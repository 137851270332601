<template>
  <div>
    <template>
      <div id="breadcrumb-alignment" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb
            :items="breadCrumb()"
          />
        </div>
      </div>
    </template>
    <b-row class="match-height">
      <b-col
        lg="12"
        md="12"
      >
     
      <b-card
        title="Edit User">
       
        <b-alert
          v-model="showDismissibleAlert"
          variant="danger"
          dismissible
          class="mb-1"
        >
          <div class="alert-body">
            {{error_message}}
          </div>
        </b-alert>
        
        <b-form @submit="formSubmit">
          
          <b-row>
  
            <b-col md="2">
  
              <b-media class="mb-2 custom_md" @click="$refs.profileInput.click()">
                <template #aside>
                  <b-avatar
                    ref="logo"
                    :src="form.profileImage.image != '' ?form.profileImage.image : ''"
                    variant="light-primary"
                    size="180px"
                    rounded
                    
                  />
                </template>
                
                <div class="d-flex flex-wrap">
                  <input
                    ref="profileInput"
                    type="file"
                    class="d-none"
                    @input="profileImageRenderer"
                  >
                </div>
              </b-media>
  
              <p class="text-danger" v-if="form.role == 'supervisor' || form.role == 'crew'">(This field is required)</p>
  
            </b-col>
            
  
            <b-col md="10">
              <div class="inner_dv">
                <legend class="legend_upper_border" style="width:250px">PERSONAL INFORMATION</legend>
                  <div class="legen_inc_div">
                    <b-row>
  
                      <b-col md="6">
                        <b-form-group
                          label="Person Name"
                          class="required legend_top_space_mobile"
                        >
                          <b-form-input
                            id="person_name"
                            placeholder="3 to 50 characters"
                            v-model="form.person_name"
                            
                          />
                        </b-form-group>
                      </b-col>
  
                      <b-col md="6">
                        <b-form-group
                          label="Email"
                          :class="isReq"
                        >
                          
                          <b-input-group class="input-group-merge">
                            <b-input-group-prepend is-text>
                              <feather-icon icon="MailIcon" />
                            </b-input-group-prepend>
                            <b-form-input
                             
                              type="text"
                              placeholder=""
                              v-model="form.email"
                              
                            />
                          </b-input-group>
                            
                        </b-form-group>
                      </b-col>
  
  
  
                    </b-row>
  
                    <b-row>
  
                      <b-col md="6">
                        <b-form-group
                          label="Username"
                          class="required"
                        >
                          <b-form-input
                            
                            placeholder="3 to 50 characters"
                            v-model="form.username"
                            
                          />
                        </b-form-group>
                      </b-col>
  
                      <b-col md="6">
                        <b-form-group
                          label="Password"
                          class=""
                        >
  
                        <b-input-group
                          class="input-group-merge"
                        >
                          <b-form-input
                            
                            v-model="form.password"
                            class="form-control-merge"
                            :type="passwordFieldType"
                            name="login-password"
                            placeholder="Password"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              class="cursor-pointer"
                              :icon="passwordToggleIcon"
                              @click="togglePasswordVisibility"
                            />
                          </b-input-group-append>
                        </b-input-group>
  
                        </b-form-group>
  
                      </b-col>
  
                    </b-row>
  
                    <b-row>
                      <b-col md="6" >
                        
                        <b-form-group
                          label="Account Type"
                          class="required"
  
                        >
                          <b-form-select v-model="form.role" @change="updateRole(); getMenus(); user_setting_updated = true">
  
                            <b-form-select-option value="" disabled>Select</b-form-select-option>
                            
                            <b-form-select-option :value="role.role" v-for="role in roles" :key="role._id">{{role.name}}</b-form-select-option>
  
                          </b-form-select>
                            
                        </b-form-group>
                      </b-col>
  
                      <b-col md="6" v-if="form.role == 'director' || form.role == 'senior_operation_manager' || form.role == 'operation_manager' || form.role == 'operation_executive' || form.role == 'site_manager' || form.role == 'site_client' || form.role == 'admin'">
  
                        <b-form-group
                          label="Project Site"
                          :class="form.role != 'director' && form.role != 'senior_operation_manager' && form.role != 'operation_manager' && form.role != 'operation_executive' ? 'required' : ''"
                        >
                          
                          <v-select
                            v-model="form.om_sites"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="site_name"
                            multiple
                            :options="om_sites"
                            placeholder="Select"
                            @input="checkOmsites($event); user_setting_updated = true"
                            :close-on-select=false
                            :clear-on-select=false
                            class="multiselect_muliple_options"
                          />
                        </b-form-group>
                      </b-col>
  
                      <b-col md="6" v-if="form.role == 'attendance' || form.role == 'panel'">
  
                        <b-form-group
                          label="Project Site"
                          class="required"
                        >
                          
                          <v-select
                            v-model="form.site_id"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="site_name"
                            :options="sites"
                            placeholder="Select"
                            @input="updateSite"
                            :clearable="false"
                          />
                        </b-form-group>
                      </b-col>
  
                      <b-col md="6" v-if="form.role == 'crew' || form.role == 'supervisor'" >
                        
                        <b-form-group
                          label="Job Title"
                          class="required"
  
                        >
                          <b-form-select v-model="form.job_title">
  
                            <b-form-select-option value="" disabled>Select</b-form-select-option>
                            
                            <b-form-select-option :value="title._id" v-for="title in job_titles" :key="title._id">{{title.job_title}}</b-form-select-option>
                            
                          </b-form-select>
                            
                        </b-form-group>
                      </b-col>
  
  
                    </b-row>
  
  
                    
                    <b-row>
  
                      <b-col md="6">
                        <b-form-group
                          label="Phone Number"
                          :class="isReq"
                        >
                          <b-input-group class="input-group-merge">
                            <b-input-group-prepend is-text>
                              <feather-icon icon="SmartphoneIcon" />
                            </b-input-group-prepend>
                            <b-form-input
                              
                              type="text"
                              placeholder="Mobile"
                              v-model="form.phone_number"
                              
                            />
                          </b-input-group>
                        
                        </b-form-group>
                      </b-col>
                      
                      <b-col md="6">
  
                        <b-form-group
                          label="Gender"
                          :class="form.role != 'attendance' && form.role != 'panel' && form.role != '' ? 'required mb-0' : 'mb-0'"
                        >
  
  
                          <div class="demo-inline-spacing">
                            <b-form-radio
                              class="mb-0 mt-1"
                              
                              name="some-radios"
                              value="male"
                              v-model="form.gender"
                            >
                              Male
                            </b-form-radio>
  
                            <b-form-radio
                              class="mb-0 mt-1"
                              
                              name="some-radios"
                              value="female"
                              v-model="form.gender"
                            >
                              Female
                            </b-form-radio>
                          </div>
                        </b-form-group>
                      </b-col>
  
                      
                    </b-row>
  
                    
  
                    <b-row v-if="form.role == 'supervisor' || form.role == 'crew'">
  
                      <b-col md="6">
                        <b-form-group
                          label="IC/ID Number"
                          class="required"
                        >
                          <b-form-input
                            
                            placeholder="3 to 50 characters"
                            v-model="form.ic_number"
                            
                          />
                        </b-form-group>
                      </b-col>
  
                    </b-row>
  
                    <div v-if="form.role == 'supervisor' || form.role == 'crew'">
  
                      <b-row>
  
                        <b-col md="12">
                          <hr />
                          <h4 class="mb-2 mt-1">Assign Project Sites</h4>
                        </b-col>
                      </b-row>
  
                      <div class="fmTable table-responsive" style="margin-bottom: 0px !important;">
                          <table class="table">
                              <thead>
                                  <tr>
                                      <th scope="col" style="width: 36%">Project Sites</th>
                                      <th scope="col" style="width: 36%">Shift</th>
                                      <th scope="col" style="width: 24%">Main Site</th>
                                      <th scope="col" style="width: 4%"></th>
                                  </tr>
                              </thead>
                              <tbody v-if="$store.getters.currentUser.role == 'administrator'">
                                  <tr v-for="(sv,index) in form.supervisor_sites" :key="index">
                                      <td>
                                        <b-form-select v-model="sv.site" @change="indexShiftSite($event,index); user_setting_updated = true">
                                          <b-form-select-option value="" disabled>Select</b-form-select-option>
                                          <b-form-select-option :value="site._id" v-for="site in sites" :disabled="disableSites.indexOf(site._id) >= 0 ? true : false" :key="site._id + index">{{site.site_name}}</b-form-select-option>
                                        </b-form-select>
                                      </td>
                                      <td>
                                        <b-form-select v-model="sv.shift">
                                          <b-form-select-option value="" disabled>Select</b-form-select-option>
                                          <b-form-select-option :value="shift._id" v-for="shift in sv.allshifts" :key="shift._id + index">{{shift.shift}}</b-form-select-option>
                                        </b-form-select>
                                      </td>
                                      <td class="text-center">
                                        <b-form-checkbox
                                          v-model="sv.main"
                                          value="yes"
                                          unchecked-value="no"
                                          class="custom-control-warning mt-1 ml-2"
                                          @change="changeMainSite($event,index); user_setting_updated = true"
                                          :key="index + 1200"
                                          :disabled="sv.site == ''"
                                        ></b-form-checkbox>
                                      </td>
                                      <td class="text-center">
                                        <b-button variant="danger" @click="removeRow(index);">-</b-button>
                                      </td>
                                  </tr>
                              </tbody>
                              <tbody v-else>
                                  <tr v-for="(sv,index) in form.supervisor_sites" v-if="$store.getters.currentUser.om_sites.indexOf(sv.site) >= 0 || sv.site == ''" :key="index">
                                      <td>
                                        <b-form-select v-model="sv.site" @change="indexShiftSite($event,index)">
                                          <b-form-select-option value="" disabled>Select</b-form-select-option>
                                          <b-form-select-option :value="site._id" v-for="site in sites" :disabled="disableSites.indexOf(site._id) >= 0 ? true : false" :key="site._id + index">{{site.site_name}}</b-form-select-option>
                                        </b-form-select>
                                      </td>
                                      <td>
                                        <b-form-select v-model="sv.shift">
                                          <b-form-select-option value="" disabled>Select</b-form-select-option>
                                          <b-form-select-option :value="shift._id" v-for="shift in sv.allshifts" :key="shift._id + index">{{shift.shift}}</b-form-select-option>
                                        </b-form-select>
                                      </td>
                                      <td class="text-center">
                                        <b-form-checkbox
                                          v-model="sv.main"
                                          value="yes"
                                          unchecked-value="no"
                                          class="custom-control-warning mt-1 ml-2"
                                          @change="changeMainSite($event,index)"
                                          :key="index + 1200"
                                          :disabled="sv.site == ''"
                                        ></b-form-checkbox>
                                      </td>
                                      <td class="text-center">
                                        <b-button variant="danger" @click="removeRow(index);">-</b-button>
                                      </td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
  
                      <!-- <b-row class="mb-1">
                        <b-col md="4">
                          <h5>Project Sites</h5>
                        </b-col>
                        <b-col md="4">
                          <h5>Shift</h5>
                        </b-col>
  
                        <b-col md="2">
                          <h5>Main Site</h5>
                        </b-col>
  
                      </b-row>
                      
                      <div v-if="$store.getters.currentUser.role == 'administrator'">
                        <b-row v-for="(sv,index) in form.supervisor_sites" :key="index" class="mcb-2">
  
                          <b-col md="4">
           
                            <b-form-select v-model="sv.site" @change="indexShiftSite($event,index)">
  
                              <b-form-select-option value="" disabled>Select</b-form-select-option>
                              
                              <b-form-select-option :value="site._id" v-for="site in sites" :disabled="disableSites.indexOf(site._id) >= 0 ? true : false" :key="site._id + index">{{site.site_name}}</b-form-select-option>
                              
                            </b-form-select>
                            
                          </b-col>
  
                          <b-col md="4">
                          
                            
                              <b-form-select v-model="sv.shift">
  
                                <b-form-select-option value="" disabled>Select</b-form-select-option>
                                
                                <b-form-select-option :value="shift._id" v-for="shift in sv.allshifts" :key="shift._id + index">{{shift.shift}}</b-form-select-option>
                                
                              </b-form-select>
                                
                            
                          </b-col>
  
                          <b-col md="2">
                            
                            <b-form-checkbox
                              v-model="sv.main"
                              value="yes"
                              unchecked-value="no"
                              class="custom-control-warning mt-1 ml-2"
                              @change="changeMainSite($event,index)"
                              :key="index + 1200"
                              :disabled="sv.site == ''"
                            >
                            
                            </b-form-checkbox>
                            
                          </b-col>
  
                          <b-col md="2" class="text-center">
                              <b-button variant="danger" @click="removeRow(index);">-</b-button>
                          </b-col>
  
                        </b-row>
                      </div>
                      <div v-else>
                        <b-row v-for="(sv,index) in form.supervisor_sites" v-if="$store.getters.currentUser.om_sites.indexOf(sv.site) >= 0 || sv.site == ''" :key="index" class="mcb-2">
  
                          <b-col md="4">
           
                            <b-form-select v-model="sv.site" @change="indexShiftSite($event,index)">
  
                              <b-form-select-option value="" disabled>Select</b-form-select-option>
                              
                              <b-form-select-option :value="site._id" v-for="site in sites" :disabled="disableSites.indexOf(site._id) >= 0 ? true : false" :key="site._id + index">{{site.site_name}}</b-form-select-option>
                              
                            </b-form-select>
                            
                          </b-col>
  
                          <b-col md="4">
                          
                            
                              <b-form-select v-model="sv.shift">
  
                                <b-form-select-option value="" disabled>Select</b-form-select-option>
                                
                                <b-form-select-option :value="shift._id" v-for="shift in sv.allshifts" :key="shift._id + index">{{shift.shift}}</b-form-select-option>
                                
                              </b-form-select>
                                
                            
                          </b-col>
  
                          <b-col md="2">
                            
                            <b-form-checkbox
                              v-model="sv.main"
                              value="yes"
                              unchecked-value="no"
                              class="custom-control-warning mt-1 ml-2"
                              @change="changeMainSite($event,index)"
                              :key="index + 1200"
                              :disabled="sv.site == ''"
                            >
                            
                            </b-form-checkbox>
                            
                          </b-col>
  
                          <b-col md="2" class="text-center">
                              <b-button variant="danger" @click="removeRow(index);">-</b-button>
                          </b-col>
  
                        </b-row>
                      </div> -->
  
  
                      <b-row class="mt-1">
                          <b-col md="12" class="text-center">
                              <hr>
                              <b-button class="mt-1" variant="primary" @click="addRow">Add More</b-button>
                          </b-col>
                      </b-row>
  
  
  
                    </div>
                    <!-- <b-row v-if="form.role == 'supervisor'">
                      <b-col md="12">
                        <b-form-group
                          label=""
                        >
                          <b-form-checkbox
                            v-model="form.main"
                            value="yes"
                            unchecked-value="no"
                            class="custom-control-warning"
                          >
                            Main Supervisor
                          </b-form-checkbox>
                          
                        </b-form-group>
                      </b-col>
                    </b-row> -->
                  </div>
              </div>
  
              <div class="inner_dv" v-if="main_site_marked == true && showHideMenu('Attendance', 'child')">
                <legend class="legend_upper_border" style="width:230px">ATTENDANCE SYSTEM</legend>
                <div class="legen_inc_div">
  
                  <b-row>
                    <b-col md="6">
                      <b-form-checkbox class="custom-control-warning mt-1" v-model="form.advanced_settings" value="yes" unchecked-value="no" @change="resetAdvancedSetting()">Need Attendance </b-form-checkbox>
                    </b-col>
  
                  </b-row>
  
                  <b-row v-if="form.advanced_settings == 'yes'" class="mt-1">
  
                    <b-col md="6">
                      <b-form-group
                        label="Payroll Paid by"
                        class="required"
                      >
                        <b-form-select v-model="form.company_name">
  
                            <b-form-select-option value="" disabled>Select</b-form-select-option> 
                            <b-form-select-option :value="name" v-for="(name,index) in payroll_names" :key="index">{{name}}</b-form-select-option>                       
                            
  
                        </b-form-select>
                      </b-form-group>
                    </b-col>
  
                    
                    <b-col md="6" >
                        <b-form-group
                          label="Work Schedule"
                          class="required"
  
                        >
                          <b-form-select v-model="form.work_schedule">
  
                            <b-form-select-option value="" disabled>Select</b-form-select-option> 
                            <b-form-select-option :value="work_schedule._id" v-for="work_schedule in work_schedules" :key="work_schedule._id">{{work_schedule.startTime | timeFormat}} - {{work_schedule.endTime | timeFormat}}</b-form-select-option>
                            
  
                          </b-form-select>
                            
                        </b-form-group>
                      </b-col>
                    
                    
                  </b-row>
                  
                  <div v-if="form.advanced_settings == 'yes'">
                    <b-row>
  
                      <b-col md="6" >
                        <b-form-group
                          label="Work Days (Per week)"
                          class="required"
  
                        >
                          <b-form-select v-model="form.work_day" @change="form.off_day = []; get7DayWork()">
  
                            <b-form-select-option value="" disabled>Select</b-form-select-option> 
                            <b-form-select-option :value="work_day._id" v-for="work_day in work_days" :key="work_day._id">{{work_day.value}} {{ isNaN(work_day.value) ? '' : 'Days'}}</b-form-select-option>                       
                            
  
                          </b-form-select>
                            
                        </b-form-group>
                      </b-col>
  
                      <b-col md="6" v-if="ph_days.indexOf(form.work_day) < 0">
                        <b-form-group
                          label="OFF Day"
                          class="required"
  
                        >
                          <v-select
                            v-model="form.off_day"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            multiple
                            :options="off_days"
                            placeholder="Select"
                            v-on:input="limiter"
                            :disabled="form.get_7_day_work"
                            :close-on-select=false
                            :clear-on-select=false
                          />
                            
                        </b-form-group>
                    </b-col>
  
                    </b-row>
  
                    
                    <!-- <b-row>
                      <b-col md="6" >
                        <b-form-group
                          label="Position"
                          class="required"
  
                        >
                          <b-form-input
                              
                              placeholder=""
                              v-model="form.position"
                              
                            />
                            
                        </b-form-group>
                      </b-col>
  
                      <b-col md="6" >
                        <b-form-group
                          label="OT Rates"
                          class="required"
  
                        >
                          <b-form-input
                              placeholder=""
                              v-model="form.ot_rate"
                            />
                            
                        </b-form-group>
                      </b-col>
  
                    </b-row> -->
                  </div>
  
                </div>
              </div>
  
              <!-- <b-row>
  
                <b-col md="12">
                  <hr />
                  <h4 class="mb-2 mt-1"> Menu Permission</h4>
                </b-col>
              </b-row> -->
  
              <div class="inner_dv" v-if="this.form.role != '' && this.form.role != 'attendance' && this.form.role != 'panel'">
                <legend class="legend_upper_border" style="width: 200px">MENU PERMISSION</legend>
                  <div class="legen_inc_div">
  
  
  
                    <b-row v-if="items && items.length > 0">
  
                      <b-col md="12">
  
                        <b-form-checkbox class="custom-control-warning" @change="selectAllMenu">Select All </b-form-checkbox>
                        <hr>
                        <!-- ---------------- new implementattion starts --------------- <br/>  -->
  
                        <div v-for="(menu, index) in items" :key="index">
                          <!-- <div v-if="showHideMenu(menu.title, 'parent')"> -->
                          <div v-if="menu.assign">
                            <b-form-checkbox class="custom-control-warning mt-1" v-model="menu.access" @change="toggleChild(menu)">{{menu.title}}</b-form-checkbox>
                            
                            <!-- level 1 menu -->
                            <div class="pl-2 row" v-if="menu.level == 1">
                              <!-- <div class="col-4 pt-1" v-for="(permission, ip) in menu.permissions" :key="ip" v-if="showHideMenuPermission(menu.title, permission.label)"> -->
                              <div class="col-4 pt-1" v-for="(permission, ip) in menu.permissions" :key="ip" v-if="permission.assign">
                                <b-form-checkbox v-model="permission.access" @change="toggleParent(menu)">{{permission.label}}</b-form-checkbox>
                              </div>
                            </div>
  
                            <!-- level 2 menu -->
                            <div v-if="menu.level == 2">
                              <div v-for="(child, ch) in menu.children" :key="ch">
                                
                                <div class="pl-2">
                                  <!-- <div v-if="showHideMenu(child.title, 'child')"> -->
                                  <div v-if="child.assign">
                                    <b-form-checkbox class="mt-2" v-model="child.access" @change="toggleChild(child); toggleParent(menu)">{{child.title}}</b-form-checkbox>
                                    <div class="pl-2 row">
                                      <!-- <div class="col-4 pt-1" v-for="(permission, ip) in child.permissions" :key="ip" v-if="showHideMenuPermission(child.title, permission.label)"> -->
                                      <div class="col-4 pt-1" v-for="(permission, ip) in child.permissions" :key="ip" v-if="permission.assign">
                                        <b-form-checkbox v-model="permission.access" @change="toggleParent(child); toggleParent2(menu);">{{permission.label}}</b-form-checkbox>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                
                              </div>
                            </div>
  
                            <!-- level 3 menu -->
                            <div v-if="menu.level == 3">
                              <div v-for="(child, ch) in menu.children" :key="ch">
                                
                                <div class="pl-2">
                                  <!-- <div v-if="showHideMenu(child.title, 'child')"> -->
                                  <div v-if="child.assign">
                                    <b-form-checkbox class="mt-2" v-model="child.access" @change="toggleChild(child); toggleParent(menu)">{{child.title}}</b-form-checkbox>
                                    
                                    <!-- having permission -->
                                    <div class="pl-2 row" v-if="child.permissions">
                                      <!-- <div class="col-4 pt-1" v-for="(permission, ipp) in child.permissions" :key="ipp" v-if="showHideMenuPermission(child.title, permission.label)"> -->
                                      <div class="col-4 pt-1" v-for="(permission, ipp) in child.permissions" :key="ipp" v-if="permission.assign">
                                        <b-form-checkbox v-model="permission.access" @change="toggleParent(child); toggleParent2(menu);">{{permission.label}}</b-form-checkbox>
                                      </div>
                                    </div>
                                    
                                    <!-- having child -->
                                    <div class="pl-2 row" v-if="child.children">
                                      <!-- <div class="col-12 pt-1" v-for="(ch, ic) in child.children" :key="ic" v-if="showHideMenuPermissionL3(menu.title, child.title, ch.title)"> -->
                                      <div class="col-12 pt-1" v-for="(ch, ic) in child.children" :key="ic" v-if="ch.assign">
                                        <b-form-checkbox v-model="ch.access" @change="toggleParent(child); toggleParent(menu); toggleChild(ch)">{{ch.title}}</b-form-checkbox>
                                          <!-- permissions of child -->
                                          <div class="pl-2 row" v-if="ch.permissions">
                                            <div class="col-4 pt-1" v-for="(pm, ipc) in ch.permissions" :key="ipc" v-if="pm.assign">
                                              <b-form-checkbox class="custom-control-primary" v-model="pm.access" @change="toggleParent(ch); toggleParent(child); toggleParent(menu); toggleParent2(child); toggleParent2(menu);">{{pm.label}}</b-form-checkbox>
                                            </div>
                                          </div>
  
                                      </div>
                                    </div>
  
                                  </div>
                                </div>
                                
                              </div>
                            </div>
  
                            <hr>
                          </div>
                        </div>
  
                      </b-col>
                    </b-row>
  
                  </div>
              </div>
              <div class="inner_dv" v-if="(form.show_custom_forms == true || form.show_custom_forms_reports == true) && (form.show_custom_forms_assign == true || form.show_custom_forms_reports_assign == true)">
                <legend class="legend_upper_border" style="width: 240px">ASSIGN CUSTOM FORMS</legend>
                  <div class="legen_inc_div">
                    <div>                 
                      
                      <b-row>
                        <b-col md="12">
                          <b-form-group
                              label="Forms"
                            >
                              <v-select
                                class="customFormUser multiselect_muliple_options"
                                v-model="form.custom_forms"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                multiple
                                :options="custom_forms"
                                placeholder="Select"
                                label="form_name"
                                @input="checkforms($event); user_setting_updated = true"
                                :close-on-select=false
                                :clear-on-select=false
                              />
                            </b-form-group>
                        </b-col>
  
                      </b-row>
                    </div>
                  </div>
              </div>
                  
  
              
              <!-- <b-row>
  
                <b-col
                  cols="6"
                  class="mb-2 flex_hundered_mobile margin_bottom_zero_mobile"
                > 
  
                  <div class="inner_dv">
                    <legend class="legend_upper_border" style="width: 150px">SIGNATURE</legend>
                    <div class="legen_inc_div new_sign_pdng">
  
                      <b-row>
                          <b-col
                            cols="6"
                            class="mb-2 mt-0  margin_bottom_zero_mobile full_height_width_img_mobile convert_col_six_to_twelve"
                          > 
                        
                              <b-media class="mb-2 mt-2 inline_media_mobile" @click="openSignature('supervisor')" v-if="form.signature.image == null">
                                <template #aside>
                                  <b-avatar
                                    ref="supervisorS"
                                    :src="form.signature.image != null ? form.signature.image : doc_icon"
                                    variant="light-info"
                                    size="200px"
                                    rounded
                                  />
                                </template>
  
                                <div class="d-flex flex-wrap">
                                  <input
                                    ref="supervisorSInput"
                                    type="file"
                                    class="d-none"
                                    @input="supervisorImageUpload"
                                  >
                                </div>
  
                                
                              </b-media>
                              //prev commented code
                              <b-media class="mb-2 mt-2" v-if="form.signature.image != null" v-viewer>
                                <template #aside>
                                  <b-avatar
                                    ref="supervisorS"
                                    :src="form.signature.image != null ? form.signature.image : doc_icon"
                                    variant="light-primary"
                                    size="200px"
                                    rounded
                                  />
                                </template>
  
                                <viewer :options="{title: false}"></viewer>
  
                                
                              </b-media>
                              //prev commented code
                              <img :src="form.signature.image" width="240px" height="200px" v-if="form.signature.image != null" style="background: #ffffff; border-radius: 4px" class="mt-1">
  
                              <center>
                              
                              <b-button
                                  variant="danger"
                                  class="mt-1 ml-2 mr-0"
                                  v-if="form.signature.image != null"
                                  @click="removeSign('supervisor')"
                                >
                                <feather-icon icon="XIcon" />
                                
                              </b-button>
                            </center>
                            </b-col>
                      </b-row>
                    </div>
                  </div>
                </b-col>
              </b-row>   -->
                  
  
            
              <b-row>
                <b-col>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="warning"
                    class="mr-1"
                  >
                    Submit
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    @click="$router.push({ name:'client-users' })"
                  >
                    Cancel
                  </b-button>
                </b-col>
            </b-row>
  
  
            </b-col>
          </b-row>
  
        </b-form>
      </b-card>
      </b-col>
    </b-row>
  
    <b-modal id="crop-modal" ref="crop-modal" centered title="Profile Image" hide-footer :no-close-on-backdrop="true" @hide="resetModal">
        
      <template>
            <div>
              
              <div class="">
                <section class="cropper-area">
  
                  <div class="img-cropper">
  
                   <vue-cropper :aspectRatio="1/1" ref="cropper" :src="temp.image" />
                    
                  </div>
  
                  <div class="pt-2 text-center float-left">
                    
                    <b-button variant="primary" class="mr-2 text-capitalize" @click.prevent="cropImage">CROP</b-button>
  
                    <b-button variant="danger" class="mr-2 text-capitalize" @click.prevent="reset">RESET</b-button>
  
                  </div>
                </section>
              </div>
            </div>
      </template>
   
    </b-modal>
  
    <b-modal
          id="signatureModel"
          ref="signatureModel"
          centered
          title="Signature"
          no-close-on-backdrop
          hide-footer
          @hide="hideSiganture"
          @show="hideSiganture"
          
        >
          <b-row>
            <b-col md="12">
  
              <!-- <VueSignaturePad
                id="signature"
                width="100%"
                height="300px"
                ref="signaturePad"
                :options="{onBegin: () => {$refs.signaturePad.resizeCanvas()},penColor: '#000000',minWidth: 3,maxWidth: 3,backgroundColor: 'rgba(255, 255, 255, 1)'}"
              /> -->
              <VueSignaturePad
                id="signature"
                width="100%"
                height="300px"
                ref="signaturePad"
                :options="{onBegin: () => {$refs.signaturePad.resizeCanvas()},penColor: '#000000',minWidth: 3,maxWidth: 3}"
              />
  
            </b-col>
          </b-row>
          <b-row class="mt-2">
  
            <b-col md="4">
              <b-button
                  variant="primary"
                  class="mt-0 w-100"
                  @click="uploadSign()"
                >
                <span class="text-nowrap">Upload Image</span>
              </b-button>
            </b-col>
  
  
            <b-col md="4">
  
                <b-button
                    variant="danger"
                    class="mt-0 w-100"
                    @click="clearSignature"
                  >
                  <span class="text-nowrap">Clear</span>
                </b-button>
              </b-col>
  
              <b-col md="4">
                <b-button
                    variant="warning"
                    class="mt-0 w-100"
                    @click="saveSignature"
                  >
                  <span class="text-nowrap">Submit</span>
                </b-button>
              </b-col>
            
  
          </b-row>
        </b-modal>
  
    
  </div>
  </template>
  
  <script>
  
  import {
    BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BInputGroupAppend,BBreadcrumb
  } from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'
  import { GET_API, POST_API } from "../../../store/actions.type"
  import VueCropper from 'vue-cropperjs';
  import vSelect from 'vue-select'
  import 'cropperjs/dist/cropper.css';
  import { togglePasswordVisibility } from '@core/mixins/ui/forms'
  import VueSimpleSuggest from 'vue-simple-suggest'
  import 'vue-simple-suggest/dist/styles.css' // Optional CSS
  
  export default {
    mixins: [togglePasswordVisibility],
    computed: {
      passwordToggleIcon() {
        return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
      },
    },
    components: {
      BAlert,
      BCard,
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BFormCheckbox,
      BForm,
      BButton,
      BFormSelect,
      BFormSelectOption,
      BFormText,
      BInputGroupPrepend,
      BInputGroup,
      BMedia,
      BAvatar,
      BTable,
      BModal,
      VueCropper,
      BFormRadio,
      vSelect,
      BInputGroupAppend,
      BBreadcrumb,
      VueSimpleSuggest
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        isReq:'',
        selectAll:false,
        doc_icon: require('@/assets/images/doc.png'),
        error_message:null,
        showDismissibleAlert:false,
        om_sites:[],
        form:{
          custom_form_access: false,
          advanced_settings:'no',
          work_schedule:'',
          off_day:[],
          work_day:'',
          position:'',
          ot_rate:'',
          company_name:'',
          
          main:'no',
          id:'',
          person_name : '',
          gender : '',
          email : '',
          phone_number : '',
          username:'',
          password:'',
          site_id:'',
          role:'',
          job_title:'',
          shift:'',
          ic_number:'',
          assign_menus : [],
          om_sites:[],
          profileImage: {
            image:null,
            name:''
          },
          thumbnail:'',
          signature:{
            image:null,
            name:''
          },
          supervisor_sites : [
            {
              site:'',
              shift:'',
              main:'no',
              allshifts:[]
            }
          ],
  
          show_custom_forms:false,
          show_custom_forms_reports:false,
          show_custom_forms_assign:false,
          show_custom_forms_reports_assign:false,
  
          custom_forms:[],
          get_7_day_work: false
        },
        custom_forms:[],
        sites:[],
        job_titles:[],
        shifts:[],
        temp:{
          image:null,
          name:'',
        },
        modelbox:'',
        roles:[],
        roles2:[],
        work_schedules:[],
        work_days:[],
        off_days:['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'],
  
        items:null,
        main_site_marked:false,
        tableColumns:[
  
          { key: 'checkbox', label: '', sortable: false , thStyle:  {width: '30%'}},
          { key: 'title', label: 'Menu Name', sortable: false , thStyle:  {width: '70%'}}
  
        ],
        payroll_names:[],
        ph_days:[],
        user_setting_updated:false, 
        disableSites:[]
      }
    },
    methods : {
      get7DayWork(){
        var result = this.work_days.find(item => item._id === this.form.work_day);
        if(result.value == "7.0"){
          this.form.get_7_day_work = true;
        } else {
          this.form.get_7_day_work = false;
        }
      },
      formSubmit(e){
        e.preventDefault();
  
        // assign menus
        this.form.assign_menus = this.items;
  
        return this.$store.dispatch(POST_API, {
             data:{
               items:this.form,
               user_setting_updated:this.user_setting_updated
             },
             api: '/api/update-client-user'
          })
          .then(() => {
              if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showDismissibleAlert = true;
                  window.scrollTo(0,0);
              } else {
                  this.showDismissibleAlert = false;
                  
                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Record Updated Successfully.',
                    showConfirmButton: false,
                    timer: 1500
                  }).then((result) => {
                      // this.$router.go(-1);
                      this.$router.push({ name:'client-users' })
                  });
                  
              }
          });
        
      },
      showImg(){
        this.$refs['crop-modal'].show();
      },
      resetModal(){
        
        if (this.modelbox == 'profile') {
  
          this.$refs.profileInput.value=null;
  
        }else{
          this.$refs.refInputEl.value=null;
        }
        
  
        this.temp.image = null;
        this.temp.name="";
  
        this.$refs['crop-modal'].hide();
      },
      cropImage() {
        
        if (this.modelbox == 'profile') {
  
          this.form.profileImage.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
          this.form.profileImage.name = this.temp.name;
  
        }else{
          this.form.document.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
          this.form.document.name = this.temp.name;
        }
  
        this.$refs['crop-modal'].hide(); 
        
      },
      reset() {
        this.$refs.cropper.reset();
      },
      
      profileImageRenderer(event){
        
        var input = event.target;
        var files = event.target.files
  
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            reader.readAsDataURL(input.files[0]);
            var image = input.files[0];
            //console.log(image);
  
            if (image.type != 'image/png' && image.type != 'image/jpeg' && image.type != 'image/jpg') {
               
                Swal.fire({
                    position: 'center',
                    icon: 'warning',
                    title: 'Please upload .png, .jpeg, .jpg images only',
                    showConfirmButton: false,
                    timer: 1500
                  })
                this.$refs.profileInput.value=null;
  
            } else if (image.size > 2097152) {
  
                Swal.fire({
                    position: 'center',
                    icon: 'warning',
                    title: 'Maximum 2 MB files allowed to be upload.',
                    showConfirmButton: false,
                    timer: 1500
                  })
                this.$refs.profileInput.value=null;
            } else {
                reader.onload = (e) => {
                    this.temp.image = e.target.result;
                    this.temp.name = image.name;
                    this.modelbox = 'profile';
                    this.showImg();
              }
            }
        }
      },
  
      
  
      siteList(){
        
        return this.$store.dispatch(POST_API, {
             data:{
                role:this.$store.getters.currentUser.role,
                om_sites:this.$store.getters.currentUser.om_sites,
             },
             api: '/api/client-sites'
          })
          .then(() => {
              if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showDismissibleAlert = true;
                  window.scrollTo(0,0);
              } else {
                  this.showDismissibleAlert = false;
                  this.sites = this.$store.getters.getResults.data;
  
                  var obj = {
                    _id:'all-site',
                    site_name:'All Project Site'
                  }
  
                  this.om_sites = [];
  
                  if (this.sites.length > 0) {
  
                    this.om_sites.push(obj);
  
                    this.sites.forEach(item => {
                      this.om_sites.push(item);
                    })
                  }
                  // console.log(this.om_sites)
                  // console.log(this.sites)
                  
                  return this.sites;
              }
          });
        
      },
      userDetail(){
        
        return this.$store.dispatch(POST_API, {
             data:{
               id: this.$route.params.id
             },
             api: '/api/client-user-detail'
          })
          .then(() => {
              if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showDismissibleAlert = true;
                  window.scrollTo(0,0);
              } else {
                  this.showDismissibleAlert = false;
                  var item = this.$store.getters.getResults.data;
                  
                  // this.form.site_id = item.site;
                  // this.getShift();
                  
  
                  this.form.id = item._id;
                  this.form.person_name  = item.full_name;
                  this.form.gender  = item.gender;
                  this.form.email  = item.email;
                  this.form.phone_number  = item.phone_number;
                  
                  this.form.username = item.username;  
                  this.form.role = item.role;
  
                  this.form.job_title = item.job_title;
                  this.form.shift = item.shift;
                  this.form.ic_number = item.ic_number;
                  this.form.main = item.main_supervisor;
  
                  if (['supervisor', 'crew'].includes(item.role)) {
                    this.form.om_sites = item.om_sites;                  
                  } else if(['attendance', 'panel'].includes(item.role)) {
                    var matchedObj = this.sites.find(obj2 => item.site._id === obj2._id);
                    if (matchedObj) {
                      this.form.site_id = matchedObj;
                      this.getShift();
                    }
                  } else {
                    var matchedSites = [];
                    item.om_sites.forEach(obj1 => {
                      var matchedObj = this.sites.find(obj2 => obj1._id === obj2._id);
                      if (matchedObj) {
                        matchedSites.push(matchedObj);
                      }
                    });
                    this.form.om_sites = matchedSites;
                  }
  
                  this.form.assign_menus = item.menu_permission;
  
                  
                  this.form.company_name = item.company_name;
                  // populate checkboxes for menus
                  this.items = item.menu_permission;
  
                  this.items.forEach(menu => {
                    this.showCustomForm(menu);
                  });
  
                  if (this.form.role == '' || this.form.role == 'crew' || this.form.role == 'attendance' || this.form.role == 'panel') {
                    this.isReq = '';
                  }else{
                    this.isReq = 'required';
                  }
  
                  this.form.profileImage.image = item.profile_image ? item.profile_image + '?'+ new Date() : null;
                  this.form.thumbnail = item.profile_thumbnail;
  
                  this.form.signature.image = item.default_signature ? item.default_signature + '?'+ new Date():null;
  
                  this.form.supervisor_sites = item.supervisor_sites;
  
                  if (typeof item.custom_forms != 'undefined' && item.custom_forms.length > 0) {
                    
                    this.form.show_custom_forms = true;
                    
                    this.form.show_custom_forms_reports = true;
  
                    this.form.custom_forms = [];
  
  
                    item.custom_forms.forEach(item => {
                      var obj = {}
                      obj._id = item._id;
                      obj.form_name = item.form.title;
  
                      this.form.custom_forms.push(obj);
                    })
  
                    //console.log(this.form.custom_forms,'--');
  
  
  
                    for (var i = 0; i < item.menu_permission.length; i++) {
                        var menu = item.menu_permission[i];
                        this.showCustomForm(menu);
                    }
                  }
  
                  this.form.advanced_settings = item.advanced_settings;
                  this.form.work_schedule = item.work_schedule ? item.work_schedule : '';
                  this.form.off_day = item.off_day;
                  this.form.work_day = item.work_day ? item.work_day : '';
                  this.form.position = item.position;
                  this.form.ot_rate = item.ot_rate ? item.ot_rate : '';
                  this.form.company_name = item.company_name;
  
                  var site = null;
                  this.main_site_marked = false;
                  if (this.form.role == 'supervisor' || this.form.role == 'crew') {
  
                    this.form.supervisor_sites.forEach(item => {
                      if (item.main == 'yes') {
                        site = item.site;
                        this.main_site_marked = true;
                      }
                      this.disableSites.push(item.site);
                    })
  
                  }
  
                  this.getWorkingSchedules(site);
  
                  this.getWorkingDays(site).then(() => {
                    this.get7DayWork();
                  });
  
                  return this.form;
              }
          });
        
      },
      dropDownData(){
        return this.$store.dispatch(POST_API, {
             data:{
               
             },
             api: '/api/client-user-dropdown'
          })
          .then(() => {
              if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showDismissibleAlert = true;
                  window.scrollTo(0,0);
              } else {
                  this.showDismissibleAlert = false;
                  var data = this.$store.getters.getResults.data;
                  this.job_titles = data.job_titles;
                  //this.shifts = data.shifts;
                  return this.job_titles;
              }
          });
      },
      updateRole(){
  
        if (this.form.role == '' || this.form.role == 'crew' || this.form.role == 'attendance' || this.form.role == 'panel') {
          this.isReq = '';
        }else{
          this.isReq = 'required';
        }
  
        this.form.site_id = '';
        this.form.job_title = '';
        this.form.shift = '';
        this.form.ic_number = '';
        this.form.main = '';
        // this.form.assign_menus = [];
        this.form.om_sites = [];
        this.form.supervisor_sites = [{
          site:'',
          shift:'',
          allshifts:[]
        }];
        this.selectAll = false;
        this.form.show_custom_forms = false;
        this.form.show_custom_forms_reports = false;
        this.form.show_custom_forms_assign = false;
        this.form.show_custom_forms_reports_assign = false;
        this.form.custom_forms = [];
  
        this.form.advanced_settings = 'no';
        this.form.work_schedule = '';
        this.form.off_day = [];
        this.form.work_day = '';
        this.form.position = '';
        this.form.ot_rate = '';
        this.form.company_name = '';
        this.work_schedules = [];
        this.work_days = [];
  
        this.main_site_marked = false;
  
        this.shifts = [];
        this.disableSites = [];
  
        
        // this.$refs.refUserListTable.refresh();
      },
      getShift(){
        
        this.form.shift = '';
  
        if (this.form.role == 'attendance' && this.form.site_id.allow_attendance == 'no') {
          /*Swal.fire({
            title: 'Attendance Not Allowed',
            text: 'This site does not having permission to use Attendance.',
            icon: 'warning',
            position: 'top-center',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
          })*/
        }
  
        return this.$store.dispatch(POST_API, {
             data:{
               site: this.form.site_id
             },
             api: '/api/site-shifts'
          })
          .then(() => {
              if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showDismissibleAlert = true;
                  window.scrollTo(0,0);
              } else {
                  this.showDismissibleAlert = false;
                  var data = this.$store.getters.getResults.data;
                  this.shifts = data;
                  return this.shifts;
              }
          });
      },
      getMenus(){
          return this.$store.dispatch(POST_API, {
             data:{
               user_type:this.form.role
             },
             api: '/api/get-menus'
          })
          .then(() => {
              if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showDismissibleAlert = true;
                  window.scrollTo(0,0);
              } else {
                  this.showDismissibleAlert = false;
  
                  this.items = this.$store.getters.getResults.data;
  
                  this.items.forEach(menu => {
                    this.showCustomForm(menu);
                  });
                  
                  return this.items;
              }
          }); 
      },
      selectall(e){
  
        this.form.assign_menus = [];
        
        if (e) {
          var selected = [];
  
          this.items.forEach(function(item){
              selected.push(item._id);
          })
  
          this.form.assign_menus = selected;
  
        }
  
      },
      hideSiganture(){
        this.openedSignModel = null;
      },
  
      openSignature(type){
  
          if (this.openedSignModel == null) {
  
              this.$refs['signatureModel'].show();
              this.openedSignModel = type;
          }
        
      },
  
      uploadSign(){
        
        if (this.openedSignModel == 'supervisor') {
  
           this.$refs['supervisorSInput'].click();
        }
  
        if (this.openedSignModel == 'client') {
  
           this.$refs['clientSInput'].click();
        }
  
        this.$refs['signatureModel'].hide();
  
      },
      clearSignature(){
        this.$refs.signaturePad.clearSignature();
      },
      saveSignature(){
        const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
        
        if (isEmpty) {
  
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: 'Signature is required',
            showConfirmButton: false,
            timer: 1500
          })
  
        }else{
  
          this.form.signature = {
            image : data,
            name : 'siganture.png',
          }
            
          this.$refs['signatureModel'].hide();
        }
      },
      supervisorImageUpload(event){
        
        var input = event.target;
        var files = event.target.files
  
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            reader.readAsDataURL(input.files[0]);
            var image = input.files[0];
            //console.log(image);
  
            if (image.type != 'image/png' && image.type != 'image/jpeg' && image.type != 'image/jpg') {
               
                Swal.fire({
                    position: 'center',
                    icon: 'warning',
                    title: 'Please upload .png, .jpeg, .jpg images only',
                    showConfirmButton: false,
                    timer: 1500
                  })
  
                this.$refs.supervisorSInput.value=null;
  
            } else if (image.size > 2097152) {
  
                Swal.fire({
                    position: 'center',
                    icon: 'warning',
                    title: 'Maximum 2 MB files allowed to be upload.',
                    showConfirmButton: false,
                    timer: 1500
                  })
                
                this.$refs.supervisorSInput.value=null;
                
  
            } else {
                reader.onload = (e) => {
  
                  /*this.supervisorSign.image = e.target.result;
                  this.supervisorSign.name = image.name;*/
                  this.form.signature = {
                    image : e.target.result,
                    name : image.name
                  }
                  
  
              }
            }
        }
        
  
      },
      removeSign(sign){
        this.form.signature.image = null;
        this.form.name='';
        this.$refs.supervisorSInput.value=null;
      },
  
      selectAllMenu(e){
        if(this.items){
          this.items.forEach(menu => {
            if(menu.assign == true){
              if(menu.level == 1){
                this.forParentAll(menu.permissions, e);
              }
              else if (menu.level == 2) {
                this.forChildAll(menu.children, e);
              }
              else if (menu.level == 3) {
                this.forChildL3All(menu.children, e);
              }
              menu.access = e;
    
              this.showCustomForm(menu);
            }
          });
        }
      },
  
      forParentAll(menu, access){
        menu.forEach(function(item){
          if(item.assign == true){
            item.access = access;
          }
        }); 
      },
  
      forChildAll(menu, access){
        menu.forEach(function(item){
          if(item.assign == true){
            item.access = access;
            if(item.permissions){
              item.permissions.forEach(function(inner_item){
                if(inner_item.assign == true){
                  inner_item.access = access;
                }
              }); 
            } else {
              item.children.forEach(function(ch){
                if(ch.assign == true){
                  ch.access = access;
                  ch.permissions.forEach(function(sci){
                    if(sci.assign == true){
                      sci.access = access;
                    }
                  }); 
                }
              });
            }
          }
        });
      },
  
      forChildL3All(menu, access){
        menu.forEach(function(item){
          if(item.assign == true){
            item.access = access;
            
            if(item.permissions){
              item.permissions.forEach(function(pm){
                if(pm.assign == true){
                  pm.access = access;
                }
              }); 
            } else {
              item.children.forEach(function(ch){
                if(ch.assign == true){
                  ch.access = access;
                  ch.permissions.forEach(function(inner_item){
                    if(inner_item.assign == true){
                      inner_item.access = access;
                    }
                  }); 
                }
              });
            }
          }
  
        });
      },
  
      toggleChild(permissions){
        this.user_setting_updated = true;
  
        if(permissions.permissions){
          this.forParentAll(permissions.permissions, permissions.access);
        } else {
          this.forChildAll(permissions.children, permissions.access);
        }
        
        this.showCustomForm(permissions);
      },
  
      forParent(menu, access){
        menu.forEach(function(item){
          item.access = access;
        }); 
      },
  
      forChild(menu, access){
        menu.forEach(function(item){
          item.access = access;
          if(item.permissions){
            item.permissions.forEach(function(inner_item){
              inner_item.access = access;
            }); 
          } else {
            item.children.forEach(function(ch){
              ch.access = access;
              ch.permissions.forEach(function(sci){
                sci.access = access;
              }); 
            });
          }
  
        });
      },
      
      forChildL3(menu, access){
        menu.forEach(function(item){
          if(item.assign == true){
            item.access = access;
  
            if(item.permissions){
              item.permissions.forEach(function(pm){
                if(pm.assign == true){
                  pm.access = access;
                }
              }); 
            } else {
              item.children.forEach(function(ch){
                if(ch.assign == true){
                ch.access = access;
                  ch.permissions.forEach(function(inner_item){
                    if(inner_item.assign == true){
                      inner_item.access = access;
                    }
                  });
                }
              });
            }
          }
          
        });
      },
  
      toggleParent(menu){
        this.user_setting_updated = true;
        var flag = false;
  
        if(menu.permissions){
          menu.permissions.forEach(item => {
            if(item.assign == true){
              if(item.access == true){
                flag = true;
              }
            }
          });
        } else {
          menu.children.forEach(item => {
            if(item.assign == true){
              if(item.access == true){
                flag = true;
              }
            }
          });
        }
        menu.access = flag;
        
        this.showCustomForm(menu);
      },
  
      toggleParent2(menu,){
        var flag = false;
        menu.children.forEach(item => {
          if(item.assign == true){
  
            if(item.permissions){
              item.permissions.forEach(elem => {
                if(elem.assign == true){
                  if(elem.access == true){
                    flag = true;
                  }
                }
              });
            } 
            else if(item.children){
  
              item.children.forEach(ch => {
                if(ch.assign == true){
                  if(ch.permissions){
  
                    ch.permissions.forEach(sch => {
                      if(sch.assign == true){
                        if(sch.access == true){
                          flag = true;
                        }
                      }
                    })
  
                  }
                }
              })
  
            }
  
          }
  
        });
        menu.access = flag;
      },
  
      getRoles(){
        return this.$store.dispatch(POST_API, {
             data:{
               role:this.$store.getters.currentUser.role,
               type:'modify'
             },
             api: '/api/get-roles'
          })
          .then(() => {
              if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showDismissibleAlert = true;
                  window.scrollTo(0,0);
              } else {
                  this.showDismissibleAlert = false;
  
                  this.roles = this.$store.getters.getResults.data;
                  
                  return this.roles;
              }
          }); 
      },
      getRoles2(){
        return this.$store.dispatch(POST_API, {
             data:{
               //role:this.$store.getters.currentUser.role
             },
             api: '/api/get-roles'
          })
          .then(() => {
              if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showDismissibleAlert = true;
                  window.scrollTo(0,0);
              } else {
                  this.showDismissibleAlert = false;
  
                  this.roles2 = this.$store.getters.getResults.data;
                  
                  return this.roles2;
              }
          }); 
      },
      checkOmsites(e){
        
        if (e.length > 0 && e[e.length-1]._id == 'all-site') {
          this.form.om_sites = [];
          this.sites.forEach(item => {
            this.form.om_sites.push(item);  
          })
        }
      },
      addRow(){
        this.form.supervisor_sites.push({
            site:'',
            shift:'',
            main:'no',
            allshifts:[]
          })
      },
  
      removeRow(index) {
  
          var di = this.disableSites.indexOf(this.form.supervisor_sites[index].site);
          if (di >= 0) {
            this.disableSites.splice(di, 1);
          }
  
          this.form.supervisor_sites.splice(index, 1);
          
          if(this.form.supervisor_sites.length == 0){
            this.main_site_marked = false;
            this.form.supervisor_sites.push({
              site:'',
              shift:'',
              main:'no',
              allshifts:[]
            })
  
          }
      },
      indexShiftSite(e,i){
        
        this.form.supervisor_sites[i].shift = '';
        this.form.supervisor_sites[i].main = 'no';
        this.form.supervisor_sites[i].allshifts = [];
  
        this.main_site_marked = false;
        this.disableSites.push(e);
  
        for (var j = 0; j < this.form.supervisor_sites.length; j++) {
          if (this.form.supervisor_sites[j].main == 'yes') {
            this.main_site_marked = true;
          }
        }
  
        return this.$store.dispatch(POST_API, {
             data:{
               site: e
             },
             api: '/api/site-shifts'
          })
          .then(() => {
              if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showDismissibleAlert = true;
                  window.scrollTo(0,0);
              } else {
                  this.showDismissibleAlert = false;
                  var data = this.$store.getters.getResults.data;
                  this.form.supervisor_sites[i].allshifts = data;
                  return this.form.supervisor_sites;
              }
          });
  
      },
      showCustomForm(menu){
        
        if (typeof menu.key != 'undefined' && menu.key == 'reports' && menu.access == false && menu.assign == false) {
          
          this.form.show_custom_forms_reports = false;
          this.form.show_custom_forms_reports_assign = false;
        }else if (typeof menu.key != 'undefined' && menu.key == 'reports' && menu.access == true && menu.assign == true) {
          for (var i = 0; i < menu.children.length; i++) {
            this.updateForms(menu.children[i]);
          }
  
        }else{
          this.updateForms(menu);
        }
      },
      updateForms(menu){
  
        if(menu.title == 'Reports'){
          var m = menu.children.filter(item => { return item.title == 'Custom Forms'});
          menu.route = m.length > 0 ? m[0].route : null;
        }
        // console.log('~~~~~~~', menu, menu.route, menu.access)
  
        if ((menu.route == 'custom-forms') && menu.access == true) {
          
          this.form.show_custom_forms = true;
  
        }else if((menu.route == 'custom-forms') && menu.access == false){
          
          this.form.show_custom_forms = false; 
        }
  
        if ((menu.route == 'custom-forms-dashboard') && menu.access == true) {
          
          this.form.show_custom_forms_reports = true;
        }else if((menu.route == 'custom-forms-dashboard') && menu.access == false){
          
          this.form.show_custom_forms_reports = false;
          
        }
        
        if ((menu.route == 'custom-forms') && menu.assign == true) {
          this.form.show_custom_forms_assign = true;
        }else if((menu.route == 'custom-forms') && menu.assign == false){
          this.form.show_custom_forms_assign = false; 
        }
  
        if ((menu.route == 'custom-forms-dashboard') && menu.assign == true) {
          this.form.show_custom_forms_reports_assign = true;
        }else if((menu.route == 'custom-forms-dashboard') && menu.assign == false){        
          this.form.show_custom_forms_reports_assign = false;        
        }
        
        // console.log('-------', menu.assign, this.form.show_custom_forms, this.form.show_custom_forms_reports)
        if ((this.form.show_custom_forms == false && this.form.show_custom_forms_reports == false) && menu.assign == true) {
            this.form.custom_form_access = false;
            for(var a = 0; a < this.items.length; a++){
              if(this.items[a].title == 'Custom Forms' && this.items[a].permissions.length > 0){
                for(var i = 0; i < this.items[a].permissions.length; i++){
                  if(this.items[a].permissions[i].access == true){
                    this.form.custom_form_access = true;
                    i = (this.items[a].permissions.length - 1);
                  }
                }
              }
              
              if(this.items[a].title == 'Reports'){
                for(var i = 0; i < this.items[a].children.length; i++){
                  if(this.items[a].children[i].title == 'Custom Forms' && this.items[a].children[i].permissions.length > 0){
                    for(var j = 0; j < this.items[a].children[i].permissions.length; j++){
                      if(this.items[a].children[i].permissions[j].access == true){
                        this.form.custom_form_access = true;
    
                        j = (this.items[a].children[i].permissions.length - 1);
                        i = (this.items[a].children.length - 1);
                      }
                    }
                  }
                }
              }
            }
  
            if(this.form.custom_form_access == false){
              this.form.custom_forms = [];
            }
        }
      },
      /*addMoreCustomForm(){
        this.form.custom_forms.push({
              form:null,
              validateLevelOne:'',
              validateLevelTwo:''
            })
      },
      removeMoreCustomForm(index){
        this.form.custom_forms.splice(index,1);
        if (this.form.custom_forms.length == 0) {
  
            this.form.custom_forms.push({
              form:null,
              validateLevelOne:'',
              validateLevelTwo:''
            })
        }
      },*/
      organizationCustomForms(){
        return this.$store.dispatch(POST_API, {
             data:{
               id:this.$store.getters.currentUser.organization
             },
             api: '/api/client-custom-forms'
          })
          .then(() => {
              if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showDismissibleAlert = true;
                  window.scrollTo(0,0);
              } else {
                  this.showDismissibleAlert = false;
  
                  var custom_forms = this.$store.getters.getResults.data;
                  
                  if (custom_forms.length > 0) {
  
                    custom_forms.sort((a, b) => (a.form.title > b.form.title ? 1 : -1))
  
                    var obj = {
                      _id:'all-forms',
                      form : {
                        title:'All Forms'
                      }
                    }
  
                    custom_forms.unshift(obj);
  
                    custom_forms.forEach(item => {
                      var obj = {}
                      obj._id = item._id;
                      obj.form_name = item.form.title;
  
                      this.custom_forms.push(obj);
                    })
  
                  }
  
                  //console.log(this.custom_forms,'++');
  
                  return this.custom_forms;
              }
          });
      },
      checkforms(e){
          if (e.length > 0 && e[e.length-1]._id == 'all-forms') {
            
            this.form.custom_forms = [];
            
            this.custom_forms.forEach(item => {
              if (item._id != 'all-forms') {
                this.form.custom_forms.push(item);
              }
            })
          }
      },
      breadCrumb(){
        var item = [{
          to:{name:'client-dashboard'},
          text: 'Dashboard',
        },{
          to: {name:'client-users'}, // hyperlink
          text: 'Users', // crumb text        
        },{
          to:null,
          text: 'Edit User'
        }];
        return item;
      },
      updateSite(){
  
        this.form.advanced_settings = 'no';
        this.form.work_schedule = '';
        this.form.off_day = [];
        this.form.work_day = '';
        this.form.position = '';
        this.form.ot_rate = '';
        this.form.company_name = '';
        this.work_schedules = [];
        this.work_days = [];
  
        var site = null;
        
        if (this.form.role == 'supervisor' || this.form.role == 'crew') {
  
          this.form.supervisor_sites.forEach(item => {
            if (item.main == 'yes') {
              site = item.site;
            }
          })
  
        }else if(this.form.role == 'attendance' || this.form.role == 'panel'){
          site = this.form.site_id._id;
          this.getShift();
  
          if (this.form.role == 'attendance') {
            this.user_setting_updated = true;
          }
  
        }
  
        this.getWorkingSchedules(site);
        this.getWorkingDays(site);
      },
      resetAdvancedSetting(){
        this.form.work_schedule = '';
        this.form.off_day = [];
        this.form.work_day = '';
        this.form.position = '';
        this.form.ot_rate = '';
        this.form.company_name = '';
      },
      getWorkingSchedules(site){
        return this.$store.dispatch(POST_API, {
           data:{
             site: site
           },
           api: '/api/get-site-work-schedule-time'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data = this.$store.getters.getResults.data;
                this.work_schedules = data;
                return this.work_schedules;
            }
        });
      },
      getWorkingDays(site){
        return this.$store.dispatch(POST_API, {
             data:{
               site: site
             },
             api: '/api/get-site-work-days'
          })
          .then(() => {
              if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showDismissibleAlert = true;
                  window.scrollTo(0,0);
              } else {
                  this.showDismissibleAlert = false;
                  var data = this.$store.getters.getResults.data;
                  this.work_days = data;
  
                  this.ph_days = [];
  
                  for(var i = 0; i < this.work_days.length; i++){
                    if(isNaN(this.work_days[i].value) == false) {
                      this.work_days[i].value = this.work_days[i].value /*+ '.0'*/
                    }
  
                    if (isNaN(this.work_days[i].value)) {
                      this.ph_days.push(this.work_days[i]._id)
                    }
                  }
                  return this.work_days;
              }
          });
      },
      changeMainSite(e,ind){
        
        if (e == 'yes') {
          for (var i = 0; i < this.form.supervisor_sites.length; i++) {
            if (ind != i) {
              this.form.supervisor_sites[i].main = 'no';
            }
          }
  
          this.main_site_marked = true;
          this.updateSite();
  
        }else if(e == 'no'){
          for (var i = 0; i < this.form.supervisor_sites.length; i++) {
            
            this.form.supervisor_sites[i].main = 'no';
            
          }
          this.main_site_marked = false;
          this.form.advanced_settings = 'no';
          this.form.company_name = '';
          this.resetAdvancedSetting();
        }
  
  
      },
      payrollNameList() {
          
          return this.$store.dispatch(POST_API, {
             data:{
              organization:this.$store.getters.currentUser.organization
             },
             api: '/api/payroll-paid-names'
          })
          .then(() => {
              if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showDismissibleAlert = true;
                  window.scrollTo(0,0);
              } else {
                  this.showDismissibleAlert = false;
                  
                  this.payroll_names = this.$store.getters.getResults.data;
                  return this.payroll_names;
                  
              }
          });
      },
      limiter(e){
        var arr = this.work_days.filter(item => { return item._id == this.form.work_day})
        var sel = 1;
        
        if (arr.length > 0 && isNaN(arr[0].value) == false) {
          sel = 7 - (Math.round(arr[0].value));
        }
        
        if(e.length > sel) {
          
          e.pop()
        }
  
        const map = {'Sunday':1,'Monday':2,'Tuesday':3,'Wednesday':4,'Thursday':5,'Friday':6,'Saturday':7};
  
        this.form.off_day.sort((a, b) => {
           return map[a] - map[b];
        });
      }
  
    },
    mounted(){
      this.siteList().then(() => {
        this.userDetail();
      });
      this.dropDownData();
      this.getRoles();
      this.getRoles2();
      this.organizationCustomForms();
      this.payrollNameList();
    }
  }
  </script>
  